// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_offer_after {
  background: #d2b356;
  display: block;
  width: 30px;
  height: 5px;
  margin-top: 10px;
}

.border_img {
  border: 5px solid #d2b356; /* Adjust border width and color */
  border-radius: 20px; /* Adjust the radius as needed */
  padding: 20px; /* Add padding for white space */
  display: inline-block; /* Ensure the container wraps around the content */
}

.border_img img {
  border-radius: 15px; /* Make the image rounded */
  display: block; /* Ensure the image fills the container */
  width: 100%; /* Make the image responsive */
}`, "",{"version":3,"sources":["webpack://./src/app/about-page/about-page.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,cAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;AACJ;;AAGA;EACI,yBAAA,EAAA,kCAAA;EACA,mBAAA,EAAA,gCAAA;EACA,aAAA,EAAA,gCAAA;EACA,qBAAA,EAAA,kDAAA;AAAJ;;AAGA;EACI,mBAAA,EAAA,2BAAA;EACA,cAAA,EAAA,yCAAA;EACA,WAAA,EAAA,8BAAA;AAAJ","sourcesContent":[".title_offer_after {\r\n    background: #d2b356;\r\n    display: block;\r\n    width: 30px;\r\n    height: 5px;\r\n    margin-top: 10px;\r\n  } \r\n\r\n\r\n.border_img {\r\n    border: 5px solid #d2b356; /* Adjust border width and color */\r\n    border-radius: 20px; /* Adjust the radius as needed */\r\n    padding: 20px; /* Add padding for white space */\r\n    display: inline-block; /* Ensure the container wraps around the content */\r\n}\r\n\r\n.border_img img {\r\n    border-radius: 15px; /* Make the image rounded */\r\n    display: block; /* Ensure the image fills the container */\r\n    width: 100%; /* Make the image responsive */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
