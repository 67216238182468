import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { ConfigService } from '../shared/services/config.service';
import { ScreenSizeService } from '../screen-size/screen-size.service';
import { ScrollService } from '../shared/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
	@Output() menuStatus: EventEmitter<any> = new EventEmitter<any>();

	menu: { id: number; title: string; link: string }[];
	menuToggle: { id: number; title: string; link: string }[];
	menuOpen: boolean;
	database = 'menuro';
	databaseToggle = 'menuTogglero';
	isDropdownOpen: boolean = false;
	selectedLanguageImg: string = 'assets/images/logo-images/flag-ro.png';

	constructor(
		private scrollService: ScrollService,
		private elementRef: ElementRef,
		private location: Location,
		private config: ConfigService,
		private screenSizeService: ScreenSizeService,
		private translate: TranslateService
	) {
		this.translate.onLangChange.subscribe(() => {
			this.database = 'menu' + this.translate.currentLang;
			this.databaseToggle = 'menuToggle' + this.translate.currentLang;
			this.getMenu();
		});
	}

	toggleDropdown() {
	  this.isDropdownOpen = !this.isDropdownOpen;
	}

	switchLang(lang: string, selectedLanguageImg: string) {
		this.translate.use(lang);
		this.selectedLanguageImg = selectedLanguageImg;
		this.isDropdownOpen = false
	}

	ngOnInit() {
		this.menuOpen = false;
		this.getMenu();
	}

	toggleMenu(status: boolean) {
		this.menuOpen = status;
	}

	getMenu() {
		this.config.getSettings(this.database).subscribe(setting => {
			this.menu = setting;
		});

		this.config.getSettings(this.databaseToggle).subscribe(setting => {
			this.menuToggle = setting;
		});
	}

	ngAfterViewInit(): void {
		this.scrollService.registerElement(this.elementRef.nativeElement.parentElement);
	}

	// toggleMenu() {
	// 	this.menuStatus.emit(!this.menuOpen);
	// }

	scrollTo(pageId: string) {
		this.scrollService.scrollToElementById(pageId);
		this.toggleMenu(!this.menuOpen);
	}
}
