import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ScreenSizeService } from '../screen-size/screen-size.service';
import { ConfigService } from '../shared/services/config.service';
import { ScrollService } from '../shared/services/scroll.service';
import { Offer } from './models/offer.model';

@Component({
  selector: 'app-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.scss']
})
export class OfferPageComponent {
  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;

  offerdata$: Observable<Offer>;
  modalRef: BsModalRef;
  selectedImage: string;
  imagesTwin: string[];
  imagesDouble: string[];
  selectedIndex: number;
  type: string;

  constructor(
    private modalService: BsModalService,
    private config: ConfigService,
    private scrollService: ScrollService,
    private screenSizeService: ScreenSizeService,
    private translate: TranslateService
  ) {
    this.loadImages('offer'+'ro');
    this.selectedIndex = 0;
  }

  ngOnInit(): void {
    this.scrollService.scrollToElement(2);

    this.getPageData('offer' + 'ro');

    this.translate.onLangChange.subscribe(() => {
      this.getPageData('offer' + this.translate.currentLang);
    });
  }

  loadImages(database: string) {
    this.config.getSettings(database).subscribe((data: any) => {
      this.imagesTwin = data.twinRoom.images;
      this.imagesDouble = data.doubleRoom.images;
    });
  }

  getPageData(database: string): void {
    this.offerdata$ = this.config.getSettings(database);
    this.offerdata$.subscribe(t => t);
  }

  openModal(imageUrl: string, type: string, index: number) {
    this.selectedImage = imageUrl;
    this.selectedIndex = index;
    this.type = type;
    this.modalRef = this.modalService.show(this.modalTemplate);
  }

  closeModal() {
    this.modalRef.hide();
  }

  nextImage() {
    if (this.type == 'twin') {
      this.selectedIndex = (this.selectedIndex + 1) % this.imagesTwin.length;
    }
    else {
      this.selectedIndex = (this.selectedIndex + 1) % this.imagesDouble.length;
    }
  }

  previousImage() {
    if (this.type == 'twin') {
      this.selectedIndex = (this.selectedIndex - 1 + this.imagesTwin.length) % this.imagesTwin.length;
    }
    else {
      this.selectedIndex = (this.selectedIndex - 1 + this.imagesDouble.length) % this.imagesDouble.length;
    }
  }
}
