import { Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Header } from '../home/models/header.model';
import { ScreenSizeService } from '../screen-size/screen-size.service';
import { ConfigService } from '../shared/services/config.service';
import { ScrollService } from '../shared/services/scroll.service';


@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent {
  @ViewChild('carouselExampleIndicators') carouselContainer: ElementRef;
  about$: Observable<Header>;

  constructor(private config: ConfigService, private scrollService: ScrollService, private screenSizeService: ScreenSizeService, private translate: TranslateService) { }

  ngOnInit(): void {
    // Example: Scroll to the third registered element
    this.scrollService.scrollToElement(2);
    this.getPageData('about' + 'ro');

    this.translate.onLangChange.subscribe(() => {
      this.getPageData('about' + this.translate.currentLang);
    });
  }

  getPageData(database: string): void {
    this.about$ = this.config.getSettings(database);
  }
}
