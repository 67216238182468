// scroll.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private elementsToScroll: HTMLElement[] = [];
  private elementsToScrollId: { [key: string]: HTMLElement } = {};

  registerElement(element: HTMLElement) {
    this.elementsToScroll.push(element);
  }

  scrollToElement(index: number) {
    if (index >= 0 && index < this.elementsToScroll.length) {
      this.elementsToScroll[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  registerElementWithId(id: string, element: HTMLElement) {
    this.elementsToScrollId[id] = element;
  }

  scrollToElementById(id: string) {
    const element = this.elementsToScrollId[id];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
