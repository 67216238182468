import { Component, ElementRef } from '@angular/core';
import { ScrollService } from './shared/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	title = 'Green Coffee House';

	constructor(private scrollService: ScrollService, private elementRef: ElementRef, private translate: TranslateService) {
		translate.setDefaultLang('ro');
	}

	switchLang(lang: string) {
		this.translate.use(lang);
	}

	ngAfterViewInit(): void {
		// Register all elements with specific IDs
		const elements = this.elementRef.nativeElement.querySelectorAll('[id]');
		elements.forEach((element: HTMLElement) => {
			this.scrollService.registerElementWithId(element.id, element);
		});
	}
}
