import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

@Injectable({
	providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
	createDb() {
		const aboutro = {
			id: 1,
			name: 'Despre Noi',
			tagline: 'SUCCESS',
			title: 'Despre noi',
			description:
				['Fie că o parte din suflet ați lăsat-o demult pe malurile Dunării, fie că ați cunoscut Sulina doar din paginile romanului lui Jean Bart, vă provocăm să uitați de freamătul marilor orașe, de grijile cotidiene și să vă lăsați purtați pe valurile Dunării până aproape de vărsarea ei în Marea Neagră, pe aleea Europolis 60.'
					, 'Uitați de voi și acceptați invitația noastră la o ceașcă aromată de cafea, la o vorbă bună, la momente de relaxare și liniște... Lăsați în urmă grijile și frământările zilnice și bucurați-vă de peisajul de basm, de zilele însorite și redesoperiți starea de bine trecându-ne pragul...'
					, 'Vă oferim condiții excelente de cazare, vă recomandăm cei mai ospitalieri localnici, cele mai gustoase preparate și modalități personalizate de petrecere a timpului liber, toate pentru a vă ajuta să trăiți farmecul de altădată al acestui oraș unic în lume...'
				],
			images: [
				'assets/images/green-coffee-house/IMG_2010.jpg',
				'assets/images/green-coffee-house/IMG_2012.jpg',
				'assets/images/green-coffee-house/IMG_2014.jpg',
				'assets/images/green-coffee-house/IMG_2016.jpg',
				'assets/images/green-coffee-house/IMG_2035.jpg',
			]
		};
		const abouten = {
			id: 1,
			name: 'About us',
			tagline: 'SUCCESS',
			title: 'About us',
			description:
				['Whether you left a part of your soul long ago on the banks of the Danube, or you have only known Sulina from the pages of Jean Bart novel, we challenge you to forget the hustle and bustle of big cities, the daily worries, and let yourselves be carried on the waves of the Danube until almost its mouth into the Black Sea, on Europolis Alley 60.',
					'Forget about yourselves and accept our invitation to a fragrant cup of coffee, to a good chat, to moments of relaxation and peace... Leave behind the daily worries and anxieties and enjoy the fairy-tale landscape, the sunny days, and rediscover the feeling of well-being by crossing our threshold...',
					'We offer excellent accommodation conditions, we recommend the most hospitable locals, the tastiest dishes, and personalized ways to spend your free time, all to help you experience the charm of this unique city in the world...'
				],
			images: [
				'assets/images/green-coffee-house/IMG_2010.jpg',
				'assets/images/green-coffee-house/IMG_2012.jpg',
				'assets/images/green-coffee-house/IMG_2014.jpg',
				'assets/images/green-coffee-house/IMG_2016.jpg',
				'assets/images/green-coffee-house/IMG_2035.jpg',
			]
		};
		const photoGallery = [
			'assets/images/green-coffee-house/exterior.jpg',
			'assets/images/green-coffee-house/exterior-1.jpg',
			'assets/images/green-coffee-house/exterior-2.jpg',
			'assets/images/green-coffee-house/exterior-3.jpg',
			'assets/images/green-coffee-house/exterior-4.jpg',
			'assets/images/green-coffee-house/exterior-5.jpg',
			'assets/images/green-coffee-house/exterior-6.jpg',
			'assets/images/green-coffee-house/exterior-7.jpg',
			'assets/images/green-coffee-house/exterior-8.jpg',
			'assets/images/green-coffee-house/exterior-9.jpg',
			'assets/images/green-coffee-house/exterior-10.jpg',
			'assets/images/green-coffee-house/exterior-11.jpg',
			'assets/images/green-coffee-house/interior.jpg',
			'assets/images/green-coffee-house/interior-1.jpg',
			'assets/images/green-coffee-house/interior-2.jpg',
			'assets/images/green-coffee-house/interior-3.jpg',
			'assets/images/green-coffee-house/interior-4.jpg',
			'assets/images/green-coffee-house/restaurant.jpg',
			'assets/images/green-coffee-house/restaurant1.jpg',
		]
		const offerro = {
			doubleRoom: {
				title: 'Cameră dublă',
				mainimg: 'assets/images/green-coffee-house/dubla1.jpg',
				images: [
					'assets/images/green-coffee-house/dubla1.jpg',
					'assets/images/green-coffee-house/dubla2.jpg',
					'assets/images/green-coffee-house/camera-1.jpg',
					'assets/images/green-coffee-house/camera-2.jpg',
					'assets/images/green-coffee-house/camera-2-1.jpg',
					'assets/images/green-coffee-house/camera-3.jpg',
					'assets/images/green-coffee-house/camera-4.jpg',
					'assets/images/green-coffee-house/camera-matrimoniala.jpg',
					'assets/images/green-coffee-house/baie.jpg',
					'assets/images/green-coffee-house/baie-4.jpg',
					'assets/images/green-coffee-house/balcon.jpg',
					'assets/images/green-coffee-house/balcon2.jpg',
				]
			},
			twinRoom: {
				title: 'Cameră twin',
				mainimg: 'assets/images/green-coffee-house/twin.jpg',
				images: [
					'assets/images/green-coffee-house/twin.jpg',
					'assets/images/green-coffee-house/camera-twin.jpg',
					'assets/images/green-coffee-house/camera-twin1.jpg',
					'assets/images/green-coffee-house/camera-twin2.jpg',
					'assets/images/green-coffee-house/camera-twin3.jpg',
					'assets/images/green-coffee-house/twin3.jpg',
					'assets/images/green-coffee-house/twin4.jpg',
					'assets/images/green-coffee-house/baie.jpg',
					'assets/images/green-coffee-house/baie-4.jpg',
					'assets/images/green-coffee-house/balcon.jpg',
					'assets/images/green-coffee-house/balcon2.jpg',
				]
			},
			description1: [
				'Camerele spațioase, mobilate elegant, cu plase de țânțari și obloane, beneficiază de serviciu zilnic de menaj.',
				'Prosoapele şi lenjeria de pat sunt incluse în tarif. Pentru un plus de confort, există articole de toaletă gratuite şi uscător de păr.',
			],
			description2: [
				'Orice tip de pat suplimentar sau pătuţ de copil este furnizat la cerere şi trebuie să fie confirmat de hotel. Suplimentele nu sunt calculate automat în preţul total şi vor fi plătite separat în timpul sejurului.',
				'Prețul include și micul dejun.'
			],
			prices: [
				{
					dates: '01.05 – 15.06',
					price: '380',
					other: '*mic dejun inclus'
				},
				{
					dates: '16.06 – 31.08',
					price: '480',
					other: '*mic dejun inclus'
				},
				{
					dates: '31.08 - 30.09',
					price: '380',
					other: '*mic dejun inclus'
				}
			],
			facilities: [
				{
					name: 'Aer condiționat',
					icon: ''
				},
				{
					name: 'Baie privată cu duș',
					icon: ''
				},
				{
					name: 'TV Led',
					icon: ''
				},
				{
					name: 'WiFi',
					icon: ''
				},
				{
					name: 'Minibar',
					icon: ''
				},
				{
					name: 'Vedere la mare',
					icon: ''
				}
			]
		};
		const offeren = {
			doubleRoom: {
				title: 'Double room',
				mainimg: 'assets/images/green-coffee-house/dubla1.jpg',
				images: [
					'assets/images/green-coffee-house/dubla2.jpg',
					'assets/images/green-coffee-house/camera-1.jpg',
					'assets/images/green-coffee-house/camera-2.jpg',
					'assets/images/green-coffee-house/camera-2-1.jpg',
					'assets/images/green-coffee-house/camera-3.jpg',
					'assets/images/green-coffee-house/camera-4.jpg',
					'assets/images/green-coffee-house/camera-matrimoniala.jpg',
					'assets/images/green-coffee-house/baie.jpg',
					'assets/images/green-coffee-house/baie-4.jpg',
					'assets/images/green-coffee-house/balcon.jpg',
					'assets/images/green-coffee-house/balcon2.jpg',
				]
			},
			twinRoom: {
				title: 'Twin room',
				mainimg: 'assets/images/green-coffee-house/twin.jpg',
				images: [
					'assets/images/green-coffee-house/camera-twin.jpg',
					'assets/images/green-coffee-house/camera-twin1.jpg',
					'assets/images/green-coffee-house/camera-twin2.jpg',
					'assets/images/green-coffee-house/camera-twin3.jpg',
					'assets/images/green-coffee-house/twin3.jpg',
					'assets/images/green-coffee-house/twin4.jpg',
					'assets/images/green-coffee-house/baie.jpg',
					'assets/images/green-coffee-house/baie-4.jpg',
					'assets/images/green-coffee-house/balcon.jpg',
					'assets/images/green-coffee-house/balcon2.jpg',
				]
			},
			description1: [
				'The spacious rooms, elegantly furnished, with mosquito nets and shutters, benefit from daily maid service.',
				'Towels and bed linen are included in the rate. For added comfort, there are complimentary toiletries and a hairdryer.'
			],
			description2: [
				'Any type of extra bed or crib is provided upon request and must be confirmed by the hotel. Supplements are not automatically calculated in the total cost and will be paid for separately during your stay.',
				'The price includes breakfast.'
			],
			prices: [
				{
					dates: '01.05 – 15.06',
					price: '380',
					other: '*breakfast included'
				},
				{
					dates: '16.06 – 31.08',
					price: '480',
					other: '*breakfast included'
				},
				{
					dates: '31.08 - 30.09',
					price: '380',
					other: '*breakfast included'
				}
			],
			facilities: [
				{
					name: 'Air conditioning',
					icon: ''
				},
				{
					name: 'Private bathroom with shower',
					icon: ''
				},
				{
					name: 'LED TV',
					icon: ''
				},
				{
					name: 'WiFi',
					icon: ''
				},
				{
					name: 'Minibar',
					icon: ''
				},
				{
					name: 'Sea view',
					icon: ''
				}
			]
		};
		const homero = {
			id: 7,
			name: 'header',
			tagline: 'GREEN COFEE HOUSE',
			title:
				'Uitați de voi și acceptați invitația noastră la o ceașcă aromată de cafea, la o vorbă bună, la momente de relaxare și liniște... Lăsați în urmă grijile și frământările zilnice și bucurați-vă de peisajul de basm, de zilele însorite și redescoperiți starea de bine trecându-ne pragul.',
			buttontext: 'Contactează-ne!',
			buttonlink: '/home',
			image: 'bg-web-3.jpg',
		};
		const homeen = {
			id: 7,
			name: 'header',
			tagline: 'GREEN COFEE HOUSE',
			title:
				'Forget about yourselves and accept our invitation to a fragrant cup of coffee, to a good chat, to moments of relaxation and peace... Leave behind the daily worries and anxieties and enjoy the fairy-tale landscape, the sunny days, and rediscover the feeling of well-being by crossing our threshold.',
			buttontext: 'Contactează-ne!',
			buttonlink: '/home',
			image: 'bg-web-3.jpg',
		};
		const footer = [
			{
				id: 6,
				name: 'footer',
				tagline: 'Made with ❤ by SculptCode',
				developer: 'SculptCode',
				developerlink: 'sculptcode.com',
			}
		];
		const attractionsro = [
			{
				id: 1,
				name: 'Catedrala Sfântul Alexandru',
				description: [
					'Catedrala ortodoxă din Alexandria, județul Teleorman, cu hramul „Sfântul Alexandru”, a fost construită în perioada 1869 - 1898. Este realizată în stil bizantino-romanic. Prăznuit pe 30 august, sfântul Alexandru a fost episcop la Constantinopol sub domnia împăratului Constantin cel Mare (313-337), luând parte la sinodul ecumenic de la Niceea în anul 325.'
					, 'Picturile murale interioare în ulei au fost realizate în anul 1898 de către o echipă formată din Constantin Artachino, Constantin Pascali și Ștefan Luchian. Picturile au fost executate în stil bizantin, iar catapeteasma a fost realizată în stil neogotic, având înălțimea de 10 metri.. După cutremurul din 1940, frescele au fost restaurate de pictorii Constantin Artachino și Ștefan Panciu.'
					, 'Inițial a fost construită din lemn, în 1836, la doi ani de la înființarea orașului de către domnitorul Țării Românești, Alexandru D. Ghica (aprilie 1834 - 7 octombrie 1842). Până în 1996 a funcționat ca Biserica Sf. Alexandru. Din 1996 a devenit catedrală episcopală. DIn anul 2004 a fost clasată ca monument istoric, cod LMI TR-II-m-A-14255.'
				],
				pictures: ['assets/images/green-coffee-house/Alexandria_Catedrala.jpg']
			},
			{
				id: 2,
				name: 'Delta Dunării',
				description:
					[
						'La 50 de metri de Green Coffee House este punctul de întâlnire cu "Domnul Edy". Cu el veți porni în aventură pe canalele Dunării... Când? Unde? Care? Cum? Totul se stabilește la o ceașcă de cafea, pe terasa Green Coffee House. Credem că tu ești cel care trebuie să decidă ora plecării, durata și destinația. Odată stabilite detaliile aventurii, uitați de griji, de frământările zilnice și bucurați-vă de peisajul de basm, de zilele însorite, lăsându-vă purtați pe valurile Dunării...'
					],
				pictures: ['assets/images/green-coffee-house/Delta_Dunarii_500.jpg']
			},
			{
				id: 3,
				name: 'Pădurea Letea',
				description:
					[
						'Pădurea Letea ( 2825 ha ) : Pădurea Letea este situată în partea de nord a Deltei, la est de comuna C.A.Rosetti. Zona strict protejată cuprinde doar o parte din suprafața totală a pădurii, parte ce a fost pusă sub ocrotire încă din anul 1930 și devenită rezervație naturală începând cu 1938.'
						, 'Pădurea s-a dezvoltat sub forma unor fâșii late (“hasmacuri”) în spațiile dintre dunele de nisip și este formată din stejar de lunca, stejar brumariu, plop alb, frasin de lunca, tei alb.'
						, 'O caracteristică a pădurii o constituie abundența de plante cataratoare: vița sălbatică, hameiul, curpenul de pădure și nu în ultimul rând liana grecească (Periploca graeca), cea care dă pădurii un aspect mediteranean. Totodată, aici au fost identificate peste 2.000 de specii de insecte, vipera de nisip (Vipera ursinii), vulturul codalb (Haliaeetus albicilla) - care cuibărește aici, trei specii de șoim, corbul, etc.'
						, 'Toate aceste specii de plante și animale, unele dintre ele foarte rare sau cu caracteristici deosebite au făcut ca această pădure să necesite o atenție deosebită pentru conservarea și păstrarea valorilor ei naturale.'
					],
				pictures: ['assets/images/green-coffee-house/padurea-letea.jpg']
			},
			{
				id: 4,
				name: 'Parcul AFDJ',
				description:
					[
						'Palatul Comisiei Europene a Dunării este un monument istoric situat pe Strada I din orașul Sulina, România. Palatul a fost sediul Comisiunii Europene a Dunării până în anul 1921, intrând apoi în administrarea statului român. Clădirea este acum sediul Secției Căilor Navigabile Sulina din cadrul Administrației Fluviale a Dunării de Jos.'
						, 'Construcția palatului a fost demarată de Comisiunea Europeană a Dunării în anul 1860 și s-a încheiat în 1868. Scopul ridicării clădirii era de a găzdui membrii Comisiei precum și activitatea Secretariatului General al acesteia. De-a lungul timpului, palatul a avut parte de mai multe renovări.'
						, 'În 1917, în timpul Primului Război Mondial, palatul a fost grav afectat de bombardamentele armatei germane. După război clădirea a fost refăcută și a continuat să fie administrată de Comisia Dunării până în 1921, când a intrat în patrimoniul statului român. Ea a fost reproiectată între anii 1922 – 1923, când a fost reconstruită pe o fundație de piatră și cu ziduri din cărămidă și cuprindea un subsol, o mansardă și două etaje.'
					],
				pictures: ['assets/images/green-coffee-house/parcul-afdj.jpg']
			},
			{
				id: 5,
				name: 'Plaja Sulina',
				description:
					[
						'Situată la numai 200 de metri de Green Coffee House, plaja Sulina este locul ideal unde îți poți petrece o zi de vacanță... sau chiar mai multe. Cât e ziua de lungă te poți relaxa la soare, pe nisipul fin, sau pe șezlong, la umbră. Seara te poți distra la o petrecere pe plajă sau la un foc de tabără, iar dacă te prinde dimineața tot aici, poți începe ziua cu o alergare ușoară, o baie în mare sau o sesiune de yoga la răsărit.'
						, 'Situată la aproximativ 3km de orașul Sulina, poți ajunge pe jos în 20-30 de minute, sau cu maxi-taxi, pe un drum ce seamănă cu un drum prin preerie. Nisipul este fin, apa mării este mică ca adâncime până în larg și este foarte limpede, lipsită de alge.'
						, 'Plaja Sulina, este o zonă de importanță ecologică, aici se dezvoltă volbura de nisip, o plantă de pe lista roșie a speciilor protejate.'
						, 'Anul acesta, se pare că prin Proiectul de amenajare a plajei de la Sulina se va compromite unicitatea acestui loc. Se ajunge cu un mijloc de transport naval din Tulcea până în localitatea Sulina și apoi la picior sau cu maxi-taxi până la plajă.'
					],
				pictures: ['assets/images/green-coffee-house/plaja-sulina.jpg']
			}
		];
		const attractionsen = [
			{
				"id": 1,
				"name": "Saint Alexander Cathedral",
				"description": [
					"The Orthodox cathedral in Alexandria, Teleorman County, dedicated to 'Saint Alexander', was built between 1869 and 1898. It is constructed in the Byzantine-Romanesque style. Celebrated on August 30th, Saint Alexander was a bishop in Constantinople during the reign of Emperor Constantine the Great (313-337), participating in the ecumenical council of Nicaea in the year 325.",
					"The interior oil mural paintings were executed in 1898 by a team consisting of Constantin Artachino, Constantin Pascali, and Ștefan Luchian. The paintings were done in the Byzantine style, and the iconostasis was crafted in the neo-Gothic style, reaching a height of 10 meters. After the earthquake of 1940, the frescoes were restored by the painters Constantin Artachino and Ștefan Panciu.",
					"Originally built of wood in 1836, two years after the founding of the city by the ruler of Wallachia, Alexandru D. Ghica (April 1834 - October 7, 1842). Until 1996, it functioned as the Church of St. Alexander. Since 1996, it has been the Episcopal Cathedral. In 2004, it was classified as a historical monument, with LMI code TR-II-m-A-14255."
				],
				"pictures": ["assets/images/green-coffee-house/Alexandria_Catedrala.jpg"]
			},
			{
				"id": 2,
				"name": "Danube Delta",
				"description": [
					"Located 50 meters from Green Coffee House is the meeting point with 'Mr. Edy'. With him, you will embark on an adventure through the channels of the Danube... When? Where? Which? How? Everything is settled over a cup of coffee, on the terrace of Green Coffee House. We believe that you should be the one to decide the departure time, duration, and destination. Once the adventure details are settled, forget about worries, daily anxieties, and enjoy the fairy-tale landscape, the sunny days, letting yourself be carried away on the waves of the Danube...",
					"Situated approximately 3km from the town of Sulina, you can reach it on foot in 20-30 minutes, or by maxi-taxi, on a road that resembles a prairie. The sand is fine, the sea water is shallow, and very clear, free of algae.",
					"Sulina Beach is an ecologically important area where sand couchgrass grows, a plant from the Red List of protected species.",
					"This year, it seems that through the Sulina Beach Arrangement Project, the uniqueness of this place will be compromised. You can reach it by boat from Tulcea to the locality of Sulina and then by foot or by maxi-taxi to the beach."
				],
				"pictures": ["assets/images/green-coffee-house/Delta_Dunarii_500.jpg"]
			},
			{
				"id": 3,
				"name": "Letea Forest",
				"description": [
					"Letea Forest (2825 ha): Letea Forest is located in the northern part of the Delta, east of the C.A.Rosetti commune. The strictly protected area covers only a part of the total forest area, a part that has been under protection since 1930 and became a natural reserve starting in 1938.",
					"The forest has developed in the form of wide strips ('hasmacuri') in the spaces between the sand dunes and is composed of riparian oak, downy oak, white poplar, riparian ash, and white lime.",
					"A characteristic of the forest is the abundance of climbing plants: wild grapevine, hops, wild cucumber, and last but not least, the Greek privet (Periploca graeca), which gives the forest a Mediterranean aspect. At the same time, over 2,000 species of insects have been identified here, including the sand viper (Vipera ursinii), the white-tailed eagle (Haliaeetus albicilla) - which nests here, three species of falcon, the raven, etc.",
					"All these plant and animal species, some of them very rare or with special characteristics, have made this forest require special attention for the conservation and preservation of its natural values."
				],
				"pictures": ["assets/images/green-coffee-house/padurea-letea.jpg"]
			},
			{
				"id": 4,
				"name": "AFDJ Park",
				"description": [
					"The Palace of the European Commission of the Danube is a historic monument located on I Street in the town of Sulina, Romania. The Palace served as the headquarters of the European Commission of the Danube until 1921, when it came under the administration of the Romanian state. The building is now the headquarters of the Sulina Waterways Section of the Lower Danube River Administration.",
					"Construction of the palace was initiated by the European Commission of the Danube in 1860 and was completed in 1868. The purpose of building the structure was to accommodate Commission members as well as the activities of its General Secretariat. Over time, the palace has undergone several renovations.",
					"In 1917, during the First World War, the palace was heavily damaged by German army bombardments. After the war, the building was rebuilt and continued to be administered by the Danube Commission until 1921, when it entered the Romanian state's heritage. It was redesigned between 1922-1923, when it was rebuilt on a stone foundation with brick walls and comprised a basement, an attic, and two floors."
				],
				"pictures": ["assets/images/green-coffee-house/parcul-afdj.jpg"]
			},
			{
				"id": 5,
				"name": "Sulina Beach",
				"description": [
					"Located just 200 meters from Green Coffee House, Sulina Beach is the ideal place to spend a day... or even more. Throughout the day, you can relax in the sun, on the fine sand, or on a sun lounger in the shade. In the evening, you can enjoy a beach party or a campfire, and if you catch the morning here, you can start the day with a light jog, a swim in the sea, or a yoga session at sunrise.",
					"Sulina Beach, situated approximately 3km from the town of Sulina, can be reached on foot in 20-30 minutes, or by maxi-taxi, on a road that resembles a prairie. The sand is fine, the sea water is shallow, and very clear, free of algae.",
					"Sulina Beach is an ecologically important area where sand couchgrass grows, a plant from the Red List of protected species.",
					"This year, it seems that through the Sulina Beach Arrangement Project, the uniqueness of this place will be compromised. You can reach it by boat from Tulcea to the locality of Sulina and then by foot or by maxi-taxi to the beach."
				],
				"pictures": ["assets/images/green-coffee-house/plaja-sulina.jpg"]
			}
		];
		const images = [
			{ id: 1, name: 'gallery-image-1.jpg' },
			{ id: 2, name: 'gallery-image-2.jpg' },
			{ id: 3, name: 'gallery-image-3.jpg' },
			{ id: 4, name: 'gallery-image-4.jpg' },
			{ id: 5, name: 'gallery-image-5.jpg' },
			{ id: 6, name: 'gallery-image-6.jpg' },
		];
		const menuro = [
			{ id: 2, pageId: "about", title: 'Despre Noi', type: 1, link: '/about' },
			{ id: 3, pageId: "offer", title: 'Ofertă', type: 1, link: '/offer' },
			{ id: 4, pageId: "home", title: 'Logo', type: 2, logoImg: 'assets/images/logo.png', link: '/home' },
			{ id: 5, pageId: "attractions", title: 'Atracții', type: 1, link: '/attractions' },
			{ id: 6, pageId: "contact", title: 'Contact', type: 1, link: '/contact' }
		];
		const menuen = [
			{ id: 2, pageId: "about", title: 'About', type: 1, link: '/about' },
			{ id: 3, pageId: "offer", title: 'Offer', type: 1, link: '/offer' },
			{ id: 4, pageId: "home", title: 'Logo', type: 2, logoImg: 'assets/images/logo.png', link: '/home' },
			{ id: 5, pageId: "attractions", title: 'Attractions', type: 1, link: '/attractions' },
			{ id: 6, pageId: "contact", title: 'Contact', type: 1, link: '/contact' }
		];
		const menuTogglero = [
			{ id: 2, pageId: "about", title: 'Despre Noi', type: 1, link: '/about' },
			{ id: 3, pageId: "offer", title: 'Ofertă', type: 1, link: '/offer' },
			{ id: 5, pageId: "attractions", title: 'Atracții', type: 1, link: '/attractions' },
			{ id: 6, pageId: "contact", title: 'Contact', type: 1, link: '/contact' }
		];
		const menuToggleen = [
			{ id: 2, pageId: "about", title: 'About', type: 1, link: '/about' },
			{ id: 3, pageId: "offer", title: 'Offer', type: 1, link: '/offer' },
			{ id: 5, pageId: "attractions", title: 'Attractions', type: 1, link: '/attractions' },
			{ id: 6, pageId: "contact", title: 'Contact', type: 1, link: '/contact' }
		];
		return {
			aboutro,
			abouten,
			offerro,
			offeren,
			photoGallery,
			menuro,
			menuen,
			menuTogglero,
			menuToggleen,
			attractionsen,
			attractionsro,
			homero,
			homeen,
			images,
			footer
		};
	};
}
