// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-text {

  font-size: 15em;
}.big-text {

  font-size: 15em;
}
`, "",{"version":3,"sources":["webpack://./src/app/notfound/notfound-page/notfound-page.component.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;AACjB,CAAC;;EAEC,eAAe;AACjB","sourcesContent":[".big-text {\r\n\r\n  font-size: 15em;\r\n}.big-text {\r\n\r\n  font-size: 15em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
