import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ReactiveFormsModule } from '@angular/forms';

// Components
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { NavigationComponent } from './navigation/navigation.component';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { NotfoundModule } from './notfound/notfound.module';

// Services
import { ConfigService } from './shared/services/config.service';
import { InMemoryDataService } from './shared/services/in-memory-data.service';
import { LayoutModule } from '@angular/cdk/layout';
import { HomePageComponent } from './home/home-page/home-page.component';
import { OfferPageComponent } from './offer-page/offer-page.component';
import { AttractionsPageComponent } from './attractions-page/attractions-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { AboutPageComponent } from './about-page/about-page.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		NavigationComponent,
		NavmenuComponent,
		HomePageComponent,
		AboutPageComponent,
		OfferPageComponent,
		AttractionsPageComponent,
		ContactPageComponent,
	],
	imports: [
		HomeModule,
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		NotfoundModule,
		ReactiveFormsModule,
		LayoutModule,
		CarouselModule,
		NgxNavbarModule,
		CollapseModule.forRoot(),
		CommonModule,
        ModalModule.forRoot(),
		HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
			dataEncapsulation: false,
			passThruUnknownUrl: true,
		}),
		TranslateModule.forRoot({
			loader: {
			  provide: TranslateLoader,
			  useFactory: HttpLoaderFactory,
			  deps: [HttpClient]
			}
		  })
	],
	providers: [ConfigService],
	bootstrap: [AppComponent],
})
export class AppModule {}
