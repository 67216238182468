import { Component } from '@angular/core';
import { ScrollService } from '../shared/services/scroll.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent {
  constructor(private scrollService: ScrollService) {}

  ngOnInit(): void {
    // Example: Scroll to the third registered element
    this.scrollService.scrollToElement(5);
  }
}
