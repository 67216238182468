import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { Header } from '../models/header.model';
import { ScreenSizeService } from '../../screen-size/screen-size.service'; 
import { ScrollService } from 'src/app/shared/services/scroll.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
})
export class HomePageComponent implements OnInit {
	header$: Observable<Header>;
	styleBackground: String;

	constructor(private config: ConfigService, private scrollService: ScrollService, private screenSizeService: ScreenSizeService, private translate: TranslateService) {}

	ngOnInit() {
		this.getPageData('home' + 'ro');

		this.translate.onLangChange.subscribe(() => {
		  this.getPageData('home' + this.translate.currentLang);
		});
		this.scrollService.scrollToElement(1);
	}

	getPageData(database: string, id?: number): void {
		this.header$ = this.config.getSettings(database, id);
	}
}
