import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ScreenSizeService } from '../screen-size/screen-size.service';
import { ConfigService } from '../shared/services/config.service';
import { ScrollService } from '../shared/services/scroll.service';
import { Attraction } from './models/attraction.model';

@Component({
  selector: 'app-attractions-page',
  templateUrl: './attractions-page.component.html',
  styleUrls: ['./attractions-page.component.scss']
})
export class AttractionsPageComponent {
  attractions$: Observable<Attraction>;

  constructor(private config: ConfigService, private scrollService: ScrollService, private screenSizeService: ScreenSizeService, private translate: TranslateService) {}

  ngOnInit(): void {
    // Example: Scroll to the third registered element
    this.scrollService.scrollToElement(4);
    
    this.getPageData('attractions' + 'ro');

    this.translate.onLangChange.subscribe(() => {
      this.getPageData('attractions' + this.translate.currentLang);
    });

    document.addEventListener('DOMContentLoaded', function() {
      const iconCardsContent = document.querySelector('.icon-cards_small_size__content');
    
      if (iconCardsContent) {
        iconCardsContent.addEventListener('click', function() {
          iconCardsContent.classList.toggle('paused');
        });
      }
    });
  }

  getPageData(database: string): void {
		this.attractions$ = this.config.getSettings(database);
	}

}
