import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ScreenSizeService } from '../screen-size/screen-size.service';
import { ScrollService } from '../shared/services/scroll.service';

@Component({
	selector: 'app-navmenu',
	templateUrl: './navmenu.component.html',
	styleUrls: ['./navmenu.component.scss'],
})
export class NavmenuComponent {
	@Input() menu: any;
	@Input() menuOpen: boolean;
	@Output() menuStatus: EventEmitter<any> = new EventEmitter<any>();

	constructor(private scrollService: ScrollService, private elementRef: ElementRef, private screenSizeService: ScreenSizeService) { }
	
	ngAfterViewInit(): void {
		this.scrollService.registerElement(this.elementRef.nativeElement.parentElement);
	  }

	toggleMenu() {
		this.menuStatus.emit(!this.menuOpen);
	}

	scrollTo(pageId: string){
		this.scrollService.scrollToElementById(pageId);
		this.toggleMenu();
	}

}
